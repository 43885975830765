@import '~public/styles/flex';
@import '~public/styles/theme';

.labelContainer {
  @extend .centerBetween;
}

.label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-purple_6);
}

.labelRight {
  cursor: pointer;
}
.inputContainer {
  position: relative;
}
.inputPrefix {
  position: absolute;
  top: 18px;
  left: 12px;
  line-height: 24px;
}

.inputSuffix {
  position: absolute;
  top: 18px;
  right: 12px;
  line-height: 24px;
  cursor: pointer;
}

.inputGroup {
  position: relative;
  margin: 5px 0 4px;
  border: 1px solid var(--color-purple_4);
  border-radius: 14px;
  overflow: hidden;
  background: none;
  &:hover {
    border-color: var(--color-greyText);
    background: var(--color-gray_3) !important;

    .divide {
      background-color: var(--color-greyText);
    }
  }

  &:focus {
    border-color: var(--color-purple_6);

    .divide {
      background-color: var(--color-purple_6);
    }
  }
}

.inputGroupError {
  border-color: var(--color-red_6) !important;
}

.divide {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 24px;
  background-color: var(--color-outline);
  transform: translate(-50%, -50%);
}

.input {
  width: 50%;
  padding: 12px;
  padding-left: 12px;
  padding-right: 12px;
  // padding-left: ${!!prefix ? 36 : 12}px;
  // padding-right: ${!!suffix ? 36 : 12}px;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-purple_6);
  outline: none;
  border: 0;
  background: none !important;

  &::placeholder {
    color: var(--color-purple_3);
    font-size: 14px;
  }

  &.inputError::placeholder {
    color: var(--color-red_6);
  }
  &.inputHasPrefix {
    padding-left: 36px;
  }
  &.inputHasSuffix {
    padding-right: 36px;
  }
}
