.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-purple_6);
}
.labelRight {
  cursor: pointer;
}
.inputContainer {
  position: relative;
}
