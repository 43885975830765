@import '~public/styles/theme';

.root {
  background-color: unset;
  border: unset;
  border-radius: 14px;
  outline: none;
  color: var(--color-white);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  line-height: 16px;
  font-family: $fontUniversalSans;
}
.rootDisabled {
  cursor: default;
}
.fullWidth {
  width: 100%;
}
.sizeSmall {
  font-size: 12px;
  padding: 7px 16px;
}
.sizeDefault {
  padding: 13px 30px;
}
.sizeLarge {
  padding: 17px 50px;
}
.sizeMini {
  font-size: 12px;
  padding: 4px 12px;
}

@media screen and (min-width: 768px) {
  .colorPrimary {
    background-color: var(--color-purple_6);
    border: 1px solid var(--color-purple_6);
    &:hover:not(&Disabled) {
      background-color: var(--color-white);
      border: 1px solid var(--color-purple_6);
      color: var(--color-purple_6);
      :global(.button-loading .dot) {
        background: var(--color-purple_6);
      }
    }
    &Disabled {
      color: var(--color-white);
      background-color: var(--color-outline);
      border: 1px solid var(--color-outline);
    }
  }

  .colorSecondary {
    background-color: var(--color-white);
    border: 1px solid var(--color-purple_6);
    color: var(--color-purple_6);
    &:hover:not(&Disabled) {
      background-color: var(--color-purple_6);
      color: var(--color-white);
      :global(.button-loading .dot) {
        background: var(--color-white);
      }
    }
    &Disabled {
      border: 1px solid var(--color-outline);
      color: var(--color-greyText);
    }
  }

  .colorDanger {
    background-color: var(--color-red_6);
    border: 1px solid var(--color-red_6);
    &:hover:not(&Disabled) {
      background-color: var(--color-white);
      border: 1px solid var(--color-red_6);
      color: var(--color-red_6);
      :global(.button-loading .dot) {
        background: var(--color-purple_6);
      }
    }
    &Disabled {
      background-color: var(--color-red_4);
      border: 1px solid var(--color-red_4);
    }
  }
}

@media screen and (max-width: 768px) {
  .colorPrimary {
    background-color: var(--color-purple_6);
    border: 1px solid var(--color-purple_6);
    &Disabled {
      color: var(--color-white);
      background-color: var(--color-outline);
      border: 1px solid var(--color-outline);
    }
  }

  .colorSecondary {
    background-color: var(--color-white);
    border: 1px solid var(--color-purple_6);
    color: var(--color-purple_6);
    &Disabled {
      border: 1px solid var(--color-outline);
      color: var(--color-greyText);
    }
  }

  .colorDanger {
    background-color: var(--color-red_6);
    border: 1px solid var(--color-red_6);

    &Disabled {
      background-color: var(--color-red_4);
      border: 1px solid var(--color-red_4);
    }
  }
}
