@import '~public/styles/flex';
@import '~public/styles/theme';

.labelContainer {
  @extend .centerBetween;
}
.label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-purple_6);
}

.labelRight {
  cursor: pointer;
}
.inputContainer {
  position: relative;
}
.inputPrefix {
  position: absolute;
  top: 18px;
  left: 12px;
  line-height: 24px;
}

.inputSuffix {
  position: absolute;
  top: 18px;
  right: 12px;
  line-height: 24px;
  cursor: pointer;
}
.input {
  margin-top: 5px;
  margin-bottom: 4px;
  width: 100%;
  padding: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 14px;
  color: var(--color-purple_6);
  outline: none;
  border: 1px solid var(--color-purple_4);
  background: none;
  &:hover {
    background: var(--color-gray_3) !important;
  }

  &:focus {
    border-color: var(--color-purple_6);
    box-shadow: $inputBoxShadowFocus !important;
  }

  &::placeholder {
    color: var(--color-purple_3);
    font-size: 14px;
  }

  &.inputError {
    box-shadow: $inputBoxShadowError !important;
    border-color: var(--color-red_6);
  }
  &.inputHasPrefix {
    padding-left: 36px;
  }
  &.inputHasSuffix {
    padding-right: 36px;
  }
}
