@import '~public/styles/flex';
@import '~public/styles/theme';

.labelContainer {
  @extend .centerBetween;
}

.label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-purple_6);
}
.labelRight {
  cursor: pointer;
}
.inputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 4px;
  border-radius: 14px;
  border: 1px solid var(--color-purple_4);
  box-shadow: none;
}
.inputContainerError {
  border: 1px solid var(--color-red_6);
  box-shadow: $inputBoxShadowError;
}

.input {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-purple_6);
  outline: none;
  background: none !important;
  border: 0;
  border-radius: 0 14px 14px 0;
  &::placeholder {
    color: var(--color-purple_3);
    font-size: 14px;
  }

  &:hover {
    background: var(--color-gray_3) !important;
  }

  &.input-error {
    border-color: var(--color-red_6);
  }
}

.dropdownIcon {
  width: 8px;
  height: 24px;
  transform: rotate(180deg);
}
.dropdownIconFocus {
  transform: rotate(0);
}
