@import '../../public/styles/theme.scss';

.root {
  display: flex;
  align-items: flex-start;
}

.container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  height: 22px;
  width: 22px;
  clear: both;
  border-radius: 6px;
  transition: all 0.3s ease-out;
  background-color: var(--color-white);
  border: 1px solid var(--color-purple_6);
  &:hover {
    background-color: var(--color-purple_1);
  }
}
.containerDisabled {
  border: 1px solid var(--color-purple_2);
  &:hover {
    background-color: var(--color-white);
  }
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
  cursor: pointer;

  &:checked + .custom {
    background-color: var(--color-green_6);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border-radius: 6px;
  }

  &:checked + .customDisabled {
    background-color: var(--color-purple_2);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border-radius: 6px;
  }
  &:checked + .custom::after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
    opacity: 1;
    transform: rotate(45deg) scale(1);
    border: solid var(--color-purple_6);
    border-width: 0 2px 2px 0;
    border-radius: 0;
  }
  &:checked + .customDisabled::after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
    opacity: 1;
    transform: rotate(45deg) scale(1);
    border: solid var(--color-white);
    border-width: 0 2px 2px 0;
    border-radius: 0;
  }
}

.custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: var(--color-transparent);
  transition: all 0.1s ease-out;
  &::after {
    position: absolute;
    content: '';
    left: 10px;
    top: 10px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
  }
}

.customDisabled {
  background-color: var(--color-white);
}
.label {
  display: inline-block;
  max-width: calc(100% - 28px);
  margin-left: 8px;
  line-height: 20px;
  font-size: 14px;
  color: var(--color-purple_6);
  font-family: $fontUniversalSans;
}
.labelDisabled {
  color: var(--color-outline);
}
