.loading {
  display: inline-block;
  text-align: center;
  height: 16px;
  z-index: 5;
}

.dot {
  display: inline-block;
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 2px 1px;
  opacity: 0;
  background: var(--color-white);
  animation: showHideDot 2.5s ease-in-out infinite;

  &__one {
    animation-delay: 0.2s;
  }
  &__two {
    animation-delay: 0.4s;
  }
  &__three {
    animation-delay: 0.6s;
  }
}

.dotPurple {
  background: var(--color-purple_6);
}

@keyframes showHideDot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
