@import '~public/styles/flex';
@import '~public/styles/theme';

.labelContainer {
  @extend .centerBetween;
}
.label {
  display: flex;
  align-items: center;
}
.labelText {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-purple_6);
}

.labelRight {
  cursor: pointer;
}
.inputContainer {
  position: relative;
}

.dropdownIcon {
  width: 8px;
  height: 24px;
  transform: rotate(180deg);
}
.dropdownIconFocus {
  transform: rotate(0);
}

.groupHeadContainer {
  display: flex;
  padding: 16px 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--color-outline);
  &:hover {
    background-color: var(--color-background);
  }
}

.groupHeadContainerActive {
  background-color: var(--color-background);
}
