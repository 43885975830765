.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.input {
  width: 40px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-grey4c);
  outline: none;
  text-align: center;
  border: none;
}
.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.symbol {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  background: var(--color-purple_6);
}
.symbolDisabled {
  background: var(--color-outline);
}

@media screen and (max-width: 768px) {
  .symbol {
    width: 20px;
    height: 20px;
    font-size: 16px;
  }
}
